  .headerContact {
    font-size:14px;
    margin-left:20px;
    display: inline-block;
    vertical-align: middle;
}

#xxxcluct {
  width: 100%;
  margin: 0 auto;/*中央寄せ*/
  position: relative;
}
.item {
  margin: 10px;
  width: 310px;
  padding: 8px;
  border: 1px solid #eee;
}
.item img {
  width: 100%;
}

